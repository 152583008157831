.app-container {
  display: flex;
  height: 100vh;
  width: 100%;
  font-family: 'Montserrat', sans-serif;
  background-color: var(--bg-primary);
  color: var(--text-primary);
}

.main-content {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
}

.controls-container-simplified {
  flex: 0 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: var(--bg-primary);
  box-shadow: 0 4px 6px var(--shadow-color);
  border-bottom: 1px solid var(--border-color);
  z-index: 10;
  width: 100%;
}

.content-wrapper {
  display: flex;
  flex: 1;
  overflow: hidden;
}

.controls-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background-color: var(--bg-primary);
  box-shadow: 0 4px 6px var(--shadow-color);
  margin-bottom: 0;
  min-height: 120px; /* Increased to fit tabs */
  width: 100%;
  z-index: 10;
  position: relative;
  border-bottom: 1px solid var(--border-color);
}

.search-bar-container-video {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0px;
  gap: 15px;
  margin-top: 5px;
  position: relative;
  z-index: 2; /* Ensure search bar is above the title */
}

.search-input-wrapper {
  display: flex;
  align-items: center;
  background-color: var(--input-bg);
  border-radius: 20px;
  border: 1px solid var(--border-color);
  padding-right: 5px;
  position: relative; /* Add position relative */
  z-index: 2; /* Ensure wrapper is above the title */
}

.search-input {
  border: none;
  padding: 10px;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  width: 300px;
  background-color: var(--input-bg);
  color: var(--text-primary);
  position: relative; /* Add position relative */
  z-index: 2; /* Ensure input is above the title */
}

.search-input::placeholder {
  color: var(--text-secondary);
}

.search-input:focus {
  outline: none;
}

.search-input-rounded-left {
  border-radius: 20px 0 0 20px;
}

.search-type-toggle {
  margin-left: 5px !important;
  /* padding-bottom: 5px; */
}

.search-type-toggle .MuiToggleButton-root {
  border: 1px solid #cb2d3e;
  color: #cb2d3e;
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  padding: 2px 10px;
  text-transform: none;
  transition:
    background-color 0.1s ease,
    color 0.1s ease;
}

.search-type-toggle .MuiToggleButton-root:hover {
  background-color: rgba(203, 45, 62, 0.04);
}

.search-type-toggle .MuiToggleButton-root.Mui-selected {
  background-color: #cb2d3e;
  color: #ffffff !important;
}

.search-type-toggle .MuiToggleButton-root.Mui-selected:hover {
  background-color: #a82222;
  color: #ffffff !important;
}

.search-type-toggle .MuiToggleButton-root:first-of-type {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.search-type-toggle .MuiToggleButton-root:last-of-type {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.refresh-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: var(--text-primary);
  transition:
    background-color 0.1s,
    color 0.1s;
}

.refresh-button:hover {
  background-color: var(--hover-bg);
  color: #cb2d3e;
}

.clear-icon {
  color: var(--text-secondary);
}

.clear-icon:hover {
  color: #000;
}

.MuiFormControl-root {
  margin: 0 !important;
}

.MuiOutlinedInput-root {
  border-radius: 20px !important;
  background-color: var(--bg-primary) !important;
  color: var(--text-primary) !important;
}

.MuiOutlinedInput-notchedOutline {
  border-color: var(--border-color) !important;
}

.owner-filter .MuiSelect-select {
  padding: 10px 26px 10px 12px !important;
  background-color: var(--bg-primary) !important;
}

.MuiMenuItem-root {
  background-color: var(--bg-secondary) !important;
  color: var(--text-primary) !important;
}

.MuiMenuItem-root:hover {
  background-color: var(--hover-bg) !important;
}

.video-list {
  width: 50%;
  height: 100vh;
  overflow-y: auto;
  position: relative;
  border-right: 1px solid #e0e0e0;
  background-color: var(--bg-secondary);
  scrollbar-width: thin;
  scrollbar-color: var(--scrollbar-thumb) var(--scrollbar-bg);
  scrollbar-gutter: stable; /* Reserve space for scrollbar */
  box-sizing: border-box; /* Include padding in width calculation */
}

.resizable-container {
  resize: horizontal;
  overflow: auto;
  max-width: 100%;
  min-width: 300px;
  border: 1px solid var(--border-color);
}

.video-list-header {
  display: flex;
  justify-content: space-between;
  padding: 16px;
  border-bottom: 1px solid #e0e0e0;
  background-color: var(--bg-secondary);
  color: var(--text-primary);
}

.video-entry {
  display: flex;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid var(--border-color);
  cursor: pointer;
  transition: background-color 0.1s ease;
  background-color: var(--bg-primary);
}

.video-entry:hover {
  background-color: var(--hover-bg);
}

.video-entry.selected {
  background-color: #1a4971; /* Darker blue for dark mode */
}

.video-list-header {
  font-weight: bold;
  background-color: var(--bg-secondary);
  color: var(--text-primary);
}

.video-list-header > div {
  flex: 1;
  text-align: right;
  padding-right: 0px;
}

.video-list-header > div:first-child {
  text-align: left;
  padding-left: 20px;
}

.video-details {
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
}

.video-details > div {
  flex: 1;
}

.video-filename {
  font-weight: bold;
  flex: 2;
}

.video-duration,
.video-owner,
.video-date,
.video-time {
  font-weight: normal;
}

.video-filename {
  text-align: left;
  padding-left: 20px;
}

.video-duration,
.video-owner,
.video-date {
  text-align: right;
}

.video-time {
  text-align: right;
}

.video-management-content-container {
  display: flex;
  flex: 1;
  overflow: hidden;
}

.resizable-container {
  resize: horizontal;
  overflow: auto;
  max-width: 50%;
  min-width: 300px;
  border: 1px solid var(--border-color);
}

.video-display {
  width: 50%;
  padding: 20px;
  flex-direction: column;
  align-items: stretch;
  background-color: var(--bg-primary);
  color: var(--text-primary);
  overflow-y: auto !important;
  padding-bottom: 20px;
  scrollbar-width: thin;
  scrollbar-color: var(--scrollbar-thumb) var(--scrollbar-bg);
  scrollbar-gutter: stable; /* Reserve space for scrollbar */
  box-sizing: border-box; /* Include padding in width calculation */
}

.plyr {
  width: 100%;
  overflow: hidden;
}

.plyr__poster {
  background-size: cover;
}

video {
  object-fit: contain;
}

/* .plyr video {
  transform: scaleX(-1);
} */

.video-metadata {
  width: 100%;
  background-color: var(--card-bg);
  box-shadow: 0 4px 6px var(--shadow-color);
  margin-top: 20px;
  border-radius: 8px;
  overflow: hidden;
  flex-grow: 1; /* Allow the metadata to grow */
  border: 1px solid var(--border-color);
}

.metadata-content {
  display: flex;
  padding: 15px;
  flex-wrap: wrap;
  padding: 15px;
  background-color: var(--bg-secondary);
  color: var(--text-primary);
}

.metadata-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: var(--bg-secondary);
  color: var(--text-primary);
  border-bottom: 1px solid var(--border-color);
}

.metadata-header .filename {
  font-size: 1.5em;
  font-weight: bold;
  margin: 0;
}

.metadata-header .trash-icon {
  font-size: 1.2em;
  cursor: pointer;
  transition: color 0.1s;
}

.metadata-header .trash-icon:hover {
  color: #f2f2f2;
}

.metadata-details {
  flex: 1 1 300px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  padding: 10px 20px;
  overflow-y: auto;
}

.metadata-item {
  display: flex;
  flex-direction: column;
  min-width: 0;
}

.metadata-label {
  font-size: 0.9em;
  color: var(--text-primary);
  margin-bottom: 5px;
  opacity: 0.7;
}

.metadata-value {
  font-size: 1.1em;
  color: var(--text-primary);
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
}

.metadata-value svg {
  margin-left: 5px;
}

.metadata-tags-container {
  grid-column: span 2; /* Make the tags container span both columns */
  background-color: #f2f0f0;
  border-radius: 4px;
  /* padding: 0 20px 10px; */
  overflow-y: auto;
  max-height: 120px; /* Set a max height */
  display: flex;
  flex-direction: column;
  margin-top: 25px;
}

.tags-scrollable {
  max-height: 120px;
  overflow-y: auto;
}

.ReactTags__tags {
  background-color: #f9f9f9;
  border-radius: 4px;
  /* padding: 5px; */
  margin-top: 5px;
}

.ReactTags__selected {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.ReactTags__tag {
  background-color: #e0e0e0;
  border-radius: 16px;
  padding: 4px 8px;
  margin: 0;
  font-size: 0.8em;
  display: inline-flex;
  align-items: center;
}

.ReactTags__remove {
  margin-left: 5px;
  color: #666;
  cursor: pointer;
  background: transparent;
  border: none;
  font-size: 12px;
}

.ReactTags__tagInputField {
  width: 100%;
  /* padding: 6px 10px; */
  border: 1px solid #ccc;
  border-radius: 20px;
  font-size: 0.8em;
  /* margin-top: 5px; */
}

.ReactTags__tags {
  background-color: #f2f2f2;
  border-radius: 4px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.ReactTags__selected {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}

.ReactTags__tag {
  background-color: #e0e0e0;
  border-radius: 16px;
  padding: 6px 10px 6px 18px;
  display: flex;
  align-items: center;
  margin-right: 5px;
  font-size: 0.9em;
}

.ReactTags__remove {
  color: #666;
  cursor: pointer;
  border: none;
  background: transparent;
  font-size: 18px;
  line-height: 1;
  padding-left: 8px;
  content: '\00D7';
  font-weight: bold;
}

.ReactTags__tagInput {
  flex-grow: 1;
  /* padding: 4px; */
}

.ReactTags__tagInputField {
  border: none;
  outline: none;
  font-size: 0.9em;
  padding: 6px 0px;
  width: 100%;
  font-family: 'Montserrat', sans-serif;
  background-color: transparent;
}

.fab {
  position: absolute;
  bottom: 20px;
  right: 20px;
  background-color: #cb2d3e;
  color: white;
  border: none;
  border-radius: 50%;
  width: 56px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  cursor: pointer;
}

.fab span {
  display: block;
  margin-top: -4px;
}

.fab .tooltip {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  white-space: nowrap;
  background-color: #ffffff;
  color: #333;
  padding: 6px 12px;
  border-radius: 10px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  font-size: 0.75rem;
  left: 0;
  top: -40px;
  transform: translateX(-50%);
  transition:
    opacity 0.1s ease,
    visibility 0s linear 0.1s;
}

.fab:hover .tooltip {
  visibility: visible;
  opacity: 1;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: var(--bg-primary);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 500px;
  position: relative;
  color: var(--text-primary);
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 1.5em;
}

.modal-input {
  width: 100%;
  padding: 8px;
  margin: 20px 0;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.modal-save {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #cb2d3e;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.modal-save:hover {
  background-color: #a82222;
}

.default-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
  color: var(--text-secondary);
}

.default-message i {
  margin-bottom: 20px;
  color: var(--text-secondary);
  opacity: 0.7;
}

.default-message p {
  font-size: 1.2em;
  font-family: 'Montserrat', sans-serif;
  color: var(--text-primary);
}

.fade-in {
  animation: fadeIn 3s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.video-player-wrapper {
  width: auto;
  background-color: var(--bg-secondary);
  border-radius: 10px;
  padding: 10px;
  display: flex;
  justify-content: center;
  box-shadow: 0 4px 6px var(--shadow-color);
}

.video-player-container {
  max-width: 80vh;
  margin: 0 auto;
  background-color: var(--bg-primary);
}

.video-player-container.square-video {
  width: 70%;
}

.video-player-container:not(.square-video) {
  width: 100%;
}

.marker-control-panel {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.marker-control-panel button {
  padding: 10px 20px;
  background-color: #cb2d3e;
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  transition: background-color 0.1s ease;
}

.marker-control-panel button:hover {
  background-color: #a82222;
}

.marker-control-panel button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.date-filter .MuiInputBase-root,
.owner-filter .MuiInputBase-root {
  border-radius: 20px;
}

.date-range-filter-videos {
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  padding: 10px;
  border-radius: 20px;
  border: 1px solid #ccc;
  background-color: #fff;
  width: 220px;
  margin: 5px !important;
}

.react-datepicker-wrapper {
  display: inline-block;
}

.react-datepicker__input-container input {
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  padding: 10px;
  border-radius: 20px;
  border: 1px solid #ccc;
  width: 100%;
}

.calendar-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 10px;
  font-size: 20px;
  color: #333;
  transition: color 0.1s ease;
}

.calendar-button:hover {
  color: #cb2d3e;
}

.react-datepicker-wrapper {
  display: inline-block;
}

.react-datepicker__input-container {
  display: inline-block;
}

/* Existing styles for the date picker itself */
.react-datepicker {
  font-family: 'Montserrat', sans-serif !important;
  border-radius: 8px !important;
  border: 1px solid #ced4da !important;
  background-color: var(--bg-secondary) !important;
}

.react-datepicker__header {
  background-color: #fff !important;
  border-bottom: 1px solid #ced4da !important;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  color: #333 !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range {
  background-color: #cb2d3e !important;
  color: #fff !important;
}

.react-datepicker__day:hover {
  background-color: #cb2d3e !important;
}

.react-datepicker__day--keyboard-selected {
  background-color: #cb2d3e !important;
  color: #fff !important;
}

.filter-button,
.reset-button {
  border-radius: 20px !important;
  text-transform: none !important;
  font-family: 'Montserrat', sans-serif !important;
}

.filter-button {
  background-color: #cb2d3e !important;
  color: #fff !important;
}

.filter-button:hover {
  background-color: #a82222 !important;
}

.reset-button {
  border-color: #cb2d3e !important;
  color: #cb2d3e !important;
}

.reset-button:hover {
  background-color: rgba(203, 45, 62, 0.04) !important;
}

.date-picker-wrapper {
  display: flex;
  align-items: center;
  background-color: var(--input-bg);
  border: 1px solid var(--border-color);
  border-radius: 20px;
  padding: 5px 10px;
  margin: 5px 0px;
}

.calendar-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 5px;
  font-size: 16px;
  color: var(--text-primary);
  transition: color 0.1s ease;
}

.calendar-button:hover {
  color: #cb2d3e;
}

.selected-date-range {
  margin: 0 10px;
  font-size: 14px;
  color: var(--text-primary);
}

.clear-date-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 5px;
  font-size: 14px;
  color: var(--text-secondary);
  transition: color 0.1s ease;
}

.clear-date-button:hover {
  color: #cb2d3e;
}

/* Update existing styles */
.date-range-filter-videos {
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  width: auto;
  margin: 5px !important;
}

/* Add these new styles */
.MuiDataGrid-cell {
  display: flex;
  align-items: center;
  justify-content: center;
}

.MuiDataGrid-cellContent {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.download-button {
  background-color: #f2f2f2;
  border: 1px solid #f2f2f2;
  border-radius: 20px;
  cursor: pointer;
  color: #cb2d3e;
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
  padding: 6px 12px;
  transition: all 0.1s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  height: 32px; /* Set a fixed height */
  width: 32px; /* Set a fixed width for a circular button */
  padding: 0; /* Remove padding */
  margin-left: 10px;
}

.download-button:hover {
  background-color: #cb2d3e;
  color: #fff;
}

.download-button:active {
  transform: scale(0.98);
}

.download-button svg {
  font-size: 16px;
}

/* Add these new styles */
.MuiDataGrid-cell {
  display: flex;
  align-items: center;
  justify-content: center;
}

.MuiDataGrid-cellContent {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.react-datepicker-wrapper {
  display: inline-block;
  width: auto;
}

.react-datepicker__input-container {
  display: inline-block;
  width: auto;
}

.archived-inventory-toggle {
  position: absolute;
  width: 20px;
  height: 20px;
  right: 20px;
  padding: 10px;
  color: #cb2d3e;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  border-radius: 20px;
  cursor: pointer;
  font-weight: 700;
  /* border-color: #cb2d3e; */
  /* border-width: 1px; */
  /* border-style: solid; */
}

.archived-inventory-toggle:hover {
  background-color: #e0e0e0;
}

.age-container {
  display: flex;
  align-items: center;
  gap: 5px;
}

.metadata-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.metadata-actions {
  display: flex;
  gap: 10px;
}
.archive-button {
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  color: #333;
  border-radius: 10px;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  /* transition: all 0.2s ease; */
}

.archive-button:hover {
  background-color: #e0e0e0;
}

.archive-button:active {
  transform: scale(0.98);
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
}

.MuiDialog-paper {
  border-radius: 8px !important;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) !important;
  font-family: 'Montserrat', sans-serif !important;
  background-color: var(--bg-secondary) !important;
  color: var(--text-primary) !important;
}

.MuiDialogTitle-root {
  background-color: var(--bg-secondary) !important;
  color: var(--text-primary) !important;
  font-size: 1.2em !important;
  font-weight: bold !important;
  padding: 10px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.MuiDialogContent-root {
  padding: 10px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
  padding-bottom: 0px !important;
  padding-top: 15px !important;
  background-color: var(--bg-secondary) !important;
  color: var(--text-primary) !important;
}

.MuiDialogContentText-root {
  color: var(--text-primary) !important;
  font-size: 1.1em !important;
}

.MuiDialogActions-root {
  /* padding: 15px 20px !important; */
  justify-content: flex-end !important;
  padding-bottom: 20px !important;
  padding-top: 5px !important;
  padding-bottom: 15px !important;
}

.MuiButton-root {
  text-transform: none !important;
  font-family: 'Montserrat', sans-serif !important;
  font-size: 14px !important;
  border-radius: 20px !important;
  padding: 6px 16px !important;
}

.MuiButton-text {
  color: #cb2d3e !important;
  font-weight: bold !important;
}

.MuiButton-contained {
  background-color: var(--button-bg) !important;
  color: var(--button-text) !important;
}

.MuiButton-contained:hover {
  opacity: 0.9 !important;
  background-color: var(--button-bg) !important;
  color: var(--button-text) !important;
}

/* Override any specific MUI button classes */
.MuiButtonBase-root.MuiButton-contained.MuiButton-containedPrimary {
  background-color: var(--button-bg) !important;
  color: var(--button-text) !important;
}

.MuiButtonBase-root.MuiButton-contained.MuiButton-containedPrimary:hover {
  opacity: 0.9 !important;
  background-color: var(--button-bg) !important;
  color: var(--button-text) !important;
}

.MuiAlert-root {
  margin-bottom: 10px !important;
}

.dialog-actions {
  margin-right: 10px !important;
}
/* .MuiTooltip-popper .MuiTooltip-tooltip {
  background-color: #333 !important;
  color: #fff !important;
  font-family: 'Montserrat', sans-serif !important;
  font-size: 12px !important;
  border-radius: 4px !important;
  padding: 8px 12px !important;
}

.MuiTooltip-popper .MuiTooltip-arrow {
  color: #333 !important;
} */

/* Audit trail */

.video-events {
  margin-top: 20px;
  background-color: var(--bg-secondary);
  border-radius: 8px;
  padding: 0px;
  box-shadow: 0 4px 6px var(--shadow-color);
  border: 1px solid var(--border-color);
  width: 100%; /* Ensure full width */
  display: flex;
  flex-direction: column;
}

.video-display .audit-log-heading {
  color: var(--text-primary);
  font-size: 1.1em;
  font-weight: 500;
  text-align: left;
  padding: 20px;
  margin-bottom: 0px;
  opacity: 1;
  background-color: var(--bg-secondary);
  border-bottom: 1px solid var(--border-color);
  margin-top: 0px;
  flex-shrink: 0; /* Prevent header from shrinking */
}

.audit-log-table {
  width: 100%;
  margin-bottom: 0;
  background-color: var(--bg-secondary) !important;
  table-layout: fixed; /* Force consistent column widths */
  min-width: 600px; /* Minimum width to prevent squishing */
}

/* Add styles for the table container */
.audit-log-table.MuiTableContainer-root {
  width: 100%;
  overflow-x: auto; /* Enable horizontal scrolling if needed */
  scrollbar-gutter: stable; /* Reserve space for scrollbar */
  box-sizing: border-box; /* Include padding in width calculation */
}

/* Style table cells for consistent widths */
.audit-log-table .MuiTableCell-root {
  white-space: nowrap; /* Prevent text wrapping */
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 12px 16px; /* Consistent padding */
}

/* Set specific column widths */
.audit-log-table .MuiTableCell-head:nth-child(1),
.audit-log-table .MuiTableCell-body:nth-child(1) {
  width: 25%; /* Date/Time column */
}

.audit-log-table .MuiTableCell-head:nth-child(2),
.audit-log-table .MuiTableCell-body:nth-child(2) {
  width: 20%; /* User column */
}

.audit-log-table .MuiTableCell-head:nth-child(3),
.audit-log-table .MuiTableCell-body:nth-child(3) {
  width: 55%; /* Event column */
}

.audit-log-table .MuiTable-root {
  background-color: var(--bg-secondary);
  width: 100%;
  table-layout: fixed;
}

.audit-log-table .MuiTableHead-root {
  background-color: var(--table-header-bg) !important;
}

.audit-log-table .MuiTableCell-head {
  font-weight: bold;
  color: var(--text-primary) !important;
  background-color: var(--table-header-bg) !important;
  border-bottom: 1px solid var(--border-color) !important;
}

.audit-log-table .MuiTableCell-body {
  color: var(--text-primary) !important;
  background-color: var(--bg-secondary) !important;
  border-bottom: 1px solid var(--border-color) !important;
}

.audit-log-table .MuiTableRow-root:hover {
  background-color: var(--table-row-hover) !important;
}

/* Remove box-shadow and border from the Paper component */
.audit-log-table.MuiPaper-root {
  box-shadow: none;
  border: none;
}

/* Remove the border-radius from the table container */
.audit-log-table.MuiTableContainer-root {
  border-radius: 0;
}

/* Add these new styles */
.share-button {
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  color: #333;
  border-radius: 10px;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}

.share-button:hover {
  background-color: #e0e0e0;
}

.share-button:active {
  transform: scale(0.98);
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
}

.share-link-container {
  display: flex;
  gap: 10px;
  margin-top: 15px;
  align-items: center;
}

.share-link-input {
  flex-grow: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  color: #333;
  background-color: #f5f5f5;
}

.share-link-input:focus {
  outline: none;
  border-color: #cb2d3e;
}

/* Update existing metadata-actions style */
.metadata-actions {
  display: flex;
  gap: 10px;
  align-items: center;
}

/* Add after the existing .MuiButton-contained:hover rule (around line 994) */

.MuiButton-contained:disabled {
  background-color: #cccccc !important;
  color: #666666 !important;
  cursor: not-allowed !important;
  box-shadow: none !important;
}

.MuiButton-contained:disabled:hover {
  background-color: #cccccc !important;
}

.share-dialog-button {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  gap: 8px !important;
  white-space: nowrap !important;
}

.share-dialog-button svg {
  font-size: 18px !important;
}

/* Max days caption in share dialog */
.max-days-wrapper {
  margin-top: 4px;
}

.max-days-wrapper * {
  font-size: 0.75rem !important;
  color: var(--text-secondary) !important;
  display: block !important;
}

/* Update the loading spinner styles */
.loading-spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(var(--bg-primary-rgb), 0.8);
  z-index: 9999;
}

.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border-radius: 8px;
  background-color: var(--bg-secondary);
  box-shadow: 0 4px 6px var(--shadow-color);
}

.spinner {
  animation: spin 1s linear infinite;
  font-size: 2em;
  color: #cb2d3e;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Add position relative to the container elements */
.resizable-container,
.archived-videos-container {
  position: relative;
}

.archive-button.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Update Material-UI specific styles */
.MuiDataGrid-root {
  color: var(--text-primary) !important;
  background-color: var(--bg-primary) !important;
  border-color: var(--border-color) !important;
}

.MuiDataGrid-columnHeaders,
.MuiDataGrid-columnHeader,
.MuiDataGrid-columnHeaderTitle,
.MuiDataGrid-columnHeaderTitleContainer {
  background-color: var(--bg-primary) !important;
  color: var(--text-primary) !important;
  font-weight: 600 !important;
  border-bottom: none !important;
}

.MuiDataGrid-columnHeader {
  outline: none !important;
}

.MuiDataGrid-columnHeaderTitle {
  font-weight: 600 !important;
  color: var(--text-primary) !important;
  opacity: 0.9 !important;
}

.MuiDataGrid-columnHeaders {
  /* border-bottom: none !important; */
  border-bottom: none !important;
}

.MuiDataGrid-cell {
  border-color: var(--border-color) !important;
  color: var(--text-primary) !important;
  background-color: var(--bg-primary) !important;
}

.MuiDataGrid-row {
  background-color: var(--bg-primary) !important;
}

.MuiDataGrid-row:hover {
  background-color: var(--hover-bg) !important;
}

/* DataGrid dark mode styles */
.MuiDataGrid-root {
  background-color: var(--bg-primary) !important;
  border-color: var(--border-color) !important;
  color: var(--text-primary) !important;
}

.MuiDataGrid-columnHeaders {
  background-color: var(--bg-primary) !important;
  /* border-bottom: 1px solid var(--border-color) !important; */
  color: var(--text-primary) !important;
  font-weight: 600 !important;
  border-bottom: none !important;
}

.MuiDataGrid-columnHeaderTitle {
  font-weight: 600 !important;
  color: var(--text-primary) !important;
  opacity: 0.9;
}

.MuiDataGrid-cell {
  border-color: var(--border-color) !important;
  color: var(--text-primary) !important;
  background-color: var(--bg-primary) !important;
}

.MuiDataGrid-row {
  background-color: var(--bg-primary) !important;
}

.MuiDataGrid-row:hover {
  background-color: var(--hover-bg) !important;
}

.MuiDataGrid-footerContainer {
  background-color: var(--bg-primary) !important;
  border-top: 1px solid var(--border-color) !important;
  color: var(--text-primary) !important;
}

.MuiDataGrid-columnSeparator {
  color: var(--border-color) !important;
}

.MuiDataGrid-menuIcon,
.MuiDataGrid-sortIcon,
.MuiDataGrid-iconButtonContainer {
  color: var(--text-primary) !important;
}

.MuiDataGrid-menuList {
  background-color: var(--bg-secondary) !important;
  color: var(--text-primary) !important;
}

.MuiDataGrid-row.Mui-selected,
.MuiDataGrid-row.Mui-selected:hover {
  background-color: var(--hover-bg) !important;
}

/* Dialog dark mode styles */
.MuiDialog-paper {
  background-color: var(--bg-secondary) !important;
  color: var(--text-primary) !important;
}

.MuiDialogTitle-root {
  background-color: var(--bg-secondary) !important;
  color: var(--text-primary) !important;
}

.MuiDialogContent-root {
  background-color: var(--bg-secondary) !important;
  color: var(--text-primary) !important;
}

/* Form controls dark mode */
.MuiOutlinedInput-root {
  background-color: var(--input-bg) !important;
  color: var(--text-primary) !important;
}

.MuiOutlinedInput-notchedOutline {
  border-color: var(--border-color) !important;
}

.MuiSelect-icon {
  color: var(--text-primary) !important;
}

.MuiMenuItem-root {
  background-color: var(--bg-secondary) !important;
  color: var(--text-primary) !important;
}

.MuiMenuItem-root:hover {
  background-color: var(--hover-bg) !important;
}

/* Video metadata section */
.video-metadata {
  background-color: var(--card-bg);
  border: 1px solid var(--border-color);
  color: var(--text-primary);
}

.metadata-header {
  border-bottom: 1px solid var(--border-color);
}

/* Audit log table */
.audit-log-table {
  background-color: var(--bg-secondary) !important;
}

.audit-log-table .MuiTableHead-root {
  background-color: var(--table-header-bg) !important;
}

.audit-log-table .MuiTableCell-head {
  color: var(--text-primary) !important;
  border-bottom: 1px solid var(--border-color) !important;
}

.audit-log-table .MuiTableCell-body {
  color: var(--text-primary) !important;
  border-bottom: 1px solid var(--border-color) !important;
}

.audit-log-table .MuiTableRow-root:hover {
  background-color: var(--table-row-hover) !important;
}

/* Buttons */
.filter-button,
.reset-button {
  background-color: var(--button-bg) !important;
  color: var(--button-text) !important;
}

.filter-button:hover,
.reset-button:hover {
  opacity: 0.9;
}

/* Date picker customization */
.react-datepicker {
  background-color: var(--bg-secondary) !important;
  border: 1px solid var(--border-color) !important;
  font-family: 'Montserrat', sans-serif !important;
  color: var(--text-primary) !important;
}

.react-datepicker__header {
  background-color: var(--table-header-bg) !important;
  border-bottom: 1px solid var(--border-color) !important;
  padding: 10px !important;
}

.react-datepicker__current-month {
  color: var(--text-primary) !important;
  font-weight: 600 !important;
}

.react-datepicker__day-name {
  color: var(--text-secondary) !important;
}

.react-datepicker__day {
  color: var(--text-primary) !important;
}

.react-datepicker__day:hover {
  background-color: var(--hover-bg) !important;
  color: var(--text-primary) !important;
}

.react-datepicker__day--selected {
  background-color: var(--button-bg) !important;
  color: var(--button-text) !important;
}

.react-datepicker__day--keyboard-selected {
  background-color: var(--button-bg) !important;
  color: var(--button-text) !important;
}

.react-datepicker__day--in-range {
  background-color: var(--button-bg) !important;
  color: var(--button-text) !important;
  opacity: 0.8;
}

.react-datepicker__day--in-selecting-range {
  background-color: var(--button-bg) !important;
  color: var(--button-text) !important;
  opacity: 0.5;
}

.react-datepicker__day--disabled {
  color: var(--text-secondary) !important;
  opacity: 0.3;
}

.react-datepicker__navigation {
  top: 13px !important;
}

.react-datepicker__navigation-icon::before {
  border-color: var(--text-primary) !important;
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow {
  border-color: var(--text-primary) !important;
}

.react-datepicker__month-container {
  background-color: var(--bg-primary) !important;
}

.react-datepicker__triangle {
  display: none !important;
}

.react-datepicker__input-container input {
  background-color: var(--input-bg) !important;
  color: var(--text-primary) !important;
  border: 1px solid var(--border-color) !important;
}

.react-datepicker-popper {
  z-index: 100 !important;
}

.MuiTablePagination-root {
  color: var(--text-primary) !important;
}

.MuiTablePagination-selectLabel,
.MuiTablePagination-displayedRows,
.MuiTablePagination-select {
  color: var(--text-primary) !important;
}

.MuiTablePagination-actions {
  color: var(--text-primary) !important;
}

.MuiTablePagination-select {
  background-color: var(--bg-primary) !important;
}

.MuiTablePagination-menuItem {
  color: var(--text-primary) !important;
  background-color: var(--bg-secondary) !important;
}

.MuiTablePagination-menuItem:hover {
  background-color: var(--hover-bg) !important;
}

/* Style for the rows per page dropdown */
.MuiSelect-select.MuiTablePagination-select {
  color: var(--text-primary) !important;
}

/* Style for the pagination buttons */
.MuiIconButton-root.Mui-disabled {
  color: var(--text-secondary) !important;
  opacity: 0.5;
}

/* Add scrollbar styling */
* {
  scrollbar-width: thin;
  scrollbar-color: var(--scrollbar-thumb) var(--scrollbar-bg);
}

*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

*::-webkit-scrollbar-track {
  background: var(--scrollbar-bg);
  border-radius: 4px;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-thumb);
  border-radius: 4px;
  border: 2px solid var(--scrollbar-bg);
}

*::-webkit-scrollbar-thumb:hover {
  background-color: var(--scrollbar-thumb-hover);
}

*::-webkit-scrollbar-corner {
  background: var(--scrollbar-bg);
}

/* Update video list scrollbar */
.video-list {
  scrollbar-width: thin;
  scrollbar-color: var(--scrollbar-thumb) var(--scrollbar-bg);
}

.video-list::-webkit-scrollbar {
  width: 8px;
}

.video-list::-webkit-scrollbar-track {
  background: var(--scrollbar-bg);
}

.video-list::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-thumb);
  border-radius: 4px;
}

.video-list::-webkit-scrollbar-thumb:hover {
  background-color: var(--scrollbar-thumb-hover);
}

/* Update video display scrollbar */
.video-display {
  scrollbar-width: thin;
  scrollbar-color: var(--scrollbar-thumb) var(--scrollbar-bg);
}

.video-display::-webkit-scrollbar {
  width: 8px;
}

.video-display::-webkit-scrollbar-track {
  background: var(--scrollbar-bg);
}

.video-display::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-thumb);
  border-radius: 4px;
}

.video-display::-webkit-scrollbar-thumb:hover {
  background-color: var(--scrollbar-thumb-hover);
}

.MuiDataGrid-scrollbarFiller {
  height: 0px !important;
}

/* Grid Columns Selector Panel */
.MuiDataGrid-paper,
.css-2qz4sq-MuiPaper-root-MuiDataGrid-paper {
  background-color: var(--bg-secondary) !important;
  color: var(--text-primary) !important;
  box-shadow:
    0px 5px 5px -3px var(--shadow-color),
    0px 8px 10px 1px var(--shadow-color),
    0px 3px 14px 2px var(--shadow-color) !important;
  top: 100% !important;
}

/* Keep the other column selector styles */
.MuiDataGrid-panelContent {
  background-color: var(--bg-secondary) !important;
  color: var(--text-primary) !important;
}

.MuiDataGrid-columnsPanel {
  background-color: var(--bg-secondary) !important;
  color: var(--text-primary) !important;
}

/* Text adornment */
.days-adornment {
  color: var(--text-primary) !important;
  font-size: 0.875rem !important;
  font-family: 'Montserrat', sans-serif !important;
}

.days-adornment-text {
  color: var(--text-primary) !important;
  font-size: 0.875rem !important;
  font-family: 'Montserrat', sans-serif !important;
}
/* Icon button adornment */
.visibility-toggle-button {
  color: var(--text-secondary) !important;
}

.visibility-toggle-button:hover {
  color: var(--text-primary) !important;
  background-color: var(--hover-bg) !important;
}

.visibility-toggle-icon {
  font-size: 1.2rem !important;
}

.redaction-success {
  color: #333333 !important;
}

/* Audit trail redacted video links styling */
.audit-log-table a.redacted-video-link {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  color: gray;
  padding: 4px;
  border-radius: 4px;
  text-decoration: none;
  border: none;
  transition: all 0.2s ease;
  margin-left: 4px;
}

.audit-log-table a.redacted-video-link:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.audit-log-table a.redacted-video-link .info-icon {
  font-size: 14px;
}

/* Dark mode support */
.dark-mode .audit-log-table a.redacted-video-link {
  color: #a0a0a0;
}

.dark-mode .audit-log-table a.redacted-video-link:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

/* Redaction badges for audit trail */
.redaction-badge-container {
  display: inline-flex;
  gap: 3px;
  margin-left: 6px;
  align-items: center;
}

.redaction-badge {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 0.7rem;
  font-weight: 500;
  padding: 1px 4px;
  border-radius: 10px;
  color: white;
  background-color: #808080;
  transition: all 0.2s ease;
}

.redaction-badge:hover {
  transform: translateY(-2px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

/* Different colors for different redaction types */
.redaction-badge.faces {
  background-color: #cb2d3e; /* red */
}

.redaction-badge.audio {
  background-color: #cb2d3e; /* Green */
}
/* Tooltip for redaction badges */
.redaction-badge-tooltip {
  position: relative;
}

.redaction-badge-tooltip::after {
  content: attr(data-tooltip);
  position: absolute;
  bottom: 125%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.7rem;
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s ease;
  z-index: 10;
}

.redaction-badge-tooltip:hover::after {
  opacity: 1;
  visibility: visible;
}

/* Dark mode support */
.dark-mode .redaction-badge {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
}

.dark-mode .redaction-badge:hover {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.6);
}

/* Animation for badges */
@keyframes badge-pop {
  0% {
    transform: scale(0.8);
    opacity: 0;
  }
  70% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.redaction-badge {
  animation: badge-pop 0.3s ease forwards;
}

/* Make redaction badges smaller */
.redaction-icon-small {
  font-size: 14px !important;
}

/* Adjust container spacing */
.redaction-badge-container {
  display: inline-flex;
  gap: 3px;
  margin-left: 6px;
  align-items: center;
}

/* Make sure badges appear in both places */
.audit-log-table .redaction-badge {
  margin-right: 2px;
  margin-left: 2px;
}

/* Add this style for the title - positioned absolutely to avoid disrupting layout */
.video-management-title {
  position: absolute;
  left: 20px;
  top: 15px;
  font-family: 'Montserrat', sans-serif;
  font-size: 24px;
  font-weight: 600;
  color: var(--text-primary);
  margin: 0;
  z-index: 1; /* Add z-index to ensure proper stacking */
}

/* SUPER HIGH PRIORITY OVERRIDE - This will force our styles no matter what */
html body .video-datagrid .MuiDataGrid-root .MuiDataGrid-row.Mui-selected,
html body [class*='MuiDataGrid-root'] [class*='MuiDataGrid-row'][class*='Mui-selected'],
html body div.video-datagrid div.MuiDataGrid-root div.MuiDataGrid-row.Mui-selected,
.MuiDataGrid-root .MuiDataGrid-row.Mui-selected {
  background-color: #ff3c52 !important;
  color: white !important;
  border-left: 6px solid #ff3c52 !important;
  border-right: 6px solid #ff3c52 !important;
  box-shadow: 0 0 20px rgba(255, 60, 82, 0.8) !important;
  z-index: 999 !important;
  font-weight: 700 !important;
  position: relative !important;
  transform: scale(1.02) !important;
  transition: all 0.3s ease !important;
}

/* Selected cell content styling */
html body .video-datagrid .MuiDataGrid-root .MuiDataGrid-row.Mui-selected .MuiDataGrid-cell,
html body [class*='MuiDataGrid-root'] [class*='MuiDataGrid-row'][class*='Mui-selected'] [class*='MuiDataGrid-cell'],
.MuiDataGrid-root .MuiDataGrid-row.Mui-selected .MuiDataGrid-cell {
  color: white !important;
  font-weight: 700 !important;
  text-shadow: 0 0 2px rgba(0, 0, 0, 0.5) !important;
  background-color: transparent !important;
  border-color: transparent !important;
}

/* Ensure the selected video stays visible in the grid */
.video-datagrid .MuiDataGrid-virtualScroller {
  scroll-behavior: smooth;
}

/* Style the pagination to be consistent with our theme */
.video-datagrid .MuiTablePagination-root {
  color: var(--text-primary);
}

.video-datagrid .MuiTablePagination-select {
  color: var(--text-primary);
}

/* Make the DataGrid more responsive */
@media (max-width: 768px) {
  .video-datagrid {
    height: 400px !important;
  }
}

/* Custom row styling with playing indicator */
.custom-row {
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
}

.playing-video {
  font-weight: 500;
  color: #cb2d3e;
}

.playing-indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #cb2d3e;
  color: white;
  animation: pulse-play 2s infinite ease-in-out;
}

.playing-indicator svg {
  font-size: 12px;
  margin-left: 2px; /* Slight adjustment for the play icon */
}

@keyframes pulse-play {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(203, 45, 62, 0.5);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 8px rgba(203, 45, 62, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(203, 45, 62, 0);
  }
}

/* Hover effects for rows with indicators */
.video-datagrid .MuiDataGrid-row:hover .playing-indicator {
  transform: scale(1.1);
}

/* Make sure the playing indicator appears in all columns if needed */
.video-datagrid .MuiDataGrid-row.Mui-selected .MuiDataGrid-cell:first-of-type {
  position: relative;
}

/* Image display styling */
.image-display {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000;
  overflow: hidden;
}

.displayed-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

/* Adjust grid styling for image content */
.MuiDataGrid-cell[data-field='formattedDuration'] .image-duration {
  color: #888;
  font-style: italic;
}

/* Add content type tabs styles */
.content-type-tabs {
  margin-top: 15px;
  width: 300px;
  margin-bottom: 15px;
}

/* Style for the individual tabs */
.content-type-tab {
  color: var(--text-primary) !important;
  font-family: 'Montserrat', sans-serif !important;
  min-width: 0 !important;
  min-height: 48px !important;
  padding: 6px 16px !important;
}

.content-type-tab .MuiSvgIcon-root {
  font-size: 1.5rem !important;
  margin: 0 !important;
}

/* Style for the content type cell in DataGrid */
.content-type-cell {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.content-type-cell .MuiSvgIcon-root {
  font-size: 1.5rem !important;
  color: var(--text-primary);
}

/* Selected tab styling */
.MuiTabs-root .Mui-selected {
  color: #cb2d3e !important;
}

/* Tab indicator styling */
.MuiTabs-indicator {
  background-color: #cb2d3e !important;
}

/* Style for display when no content is selected */
.filtered-empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
  color: var(--text-secondary);
}

.filtered-empty-state svg {
  font-size: 48px;
  margin-bottom: 16px;
  color: var(--text-secondary);
  opacity: 0.7;
}

.filtered-empty-state h3 {
  margin: 0 0 8px 0;
  color: var(--text-primary);
}

.filtered-empty-state p {
  margin: 0;
  color: var(--text-secondary);
}

/* Add ultimate high-specificity selector for selected rows */
html body .video-datagrid .MuiDataGrid-root .MuiDataGrid-row.Mui-selected,
html body [class*='MuiDataGrid-root'] [class*='MuiDataGrid-row'][class*='Mui-selected'] {
  background-color: #ff3c52 !important;
  color: white !important;
  font-weight: bold !important;
  border-left: 6px solid #ff7a85 !important;
  border-right: 6px solid #ff7a85 !important;
  box-shadow: 0 0 8px rgba(255, 60, 82, 0.5) !important;
  transform: scale(1.01) !important;
  position: relative;
  z-index: 999 !important;
}

/* Handle dark mode with custom properties */
[data-theme='dark'] .video-datagrid .MuiDataGrid-root .MuiDataGrid-row.Mui-selected,
[data-theme='dark'] [class*='MuiDataGrid-root'] [class*='MuiDataGrid-row'][class*='Mui-selected'] {
  background-color: #bd2038 !important; /* Darker red for dark mode */
}

/* Override cell text content color in selected rows */
html body .video-datagrid .MuiDataGrid-root .MuiDataGrid-row.Mui-selected .MuiDataGrid-cell > *,
html body .video-datagrid .MuiDataGrid-root .MuiDataGrid-row.Mui-selected .MuiDataGrid-cell div,
html body .video-datagrid .MuiDataGrid-root .MuiDataGrid-row.Mui-selected .MuiDataGrid-cell span {
  color: white !important;
  text-shadow: 0 0 3px rgba(0, 0, 0, 0.7) !important;
  font-weight: 700 !important;
}

/* Make sure the selected row is clearly visible on hover */
html body .video-datagrid .MuiDataGrid-root .MuiDataGrid-row.Mui-selected:hover {
  background-color: #ff516c !important;
}

/* Ensure even icon colors are overridden */
html body .video-datagrid .MuiDataGrid-root .MuiDataGrid-row.Mui-selected .MuiSvgIcon-root {
  color: white !important;
  filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.4)) !important;
}

/* Style the playing indicator when in a selected row */
html body .video-datagrid .MuiDataGrid-root .MuiDataGrid-row.Mui-selected .playing-indicator {
  background-color: white !important;
}

html body .video-datagrid .MuiDataGrid-root .MuiDataGrid-row.Mui-selected .playing-indicator svg {
  color: #ff3c52 !important;
}

/* Change the pulse animation colors for selected rows */
html body .video-datagrid .MuiDataGrid-root .MuiDataGrid-row.Mui-selected .playing-indicator {
  animation: pulse-play-selected 2s infinite ease-in-out !important;
}

@keyframes pulse-play-selected {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 8px rgba(255, 255, 255, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

.video-filters-sidebar {
  width: 250px;
  height: 100%;
  background-color: var(--bg-secondary);
  padding: 20px 15px;
  display: flex;
  flex-direction: column;
  border-right: 1px solid var(--border-color);
  overflow-y: auto;
  flex-shrink: 0;
  z-index: 900;
  box-shadow: 2px 0 5px var(--shadow-color);
}

.sidebar-section {
  margin-bottom: 25px;
}

.sidebar-section-title {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 10px;
  color: var(--text-primary);
  text-transform: uppercase;
  letter-spacing: 0.5px;
  border-bottom: 1px solid var(--border-color);
  padding-bottom: 8px;
}

.content-type-tabs-vertical {
  margin-bottom: 15px;
}

.content-type-tabs-vertical .MuiTabs-root {
  min-height: 150px;
}

.content-type-tabs-vertical .MuiTabs-indicator {
  width: 3px;
  left: 0;
  right: auto;
}

.content-type-tabs-vertical .MuiTab-root {
  min-width: auto;
  padding: 12px 0;
  margin-bottom: 5px;
}

.search-type-toggle-vertical {
  width: 100%;
  flex-direction: column;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  overflow: hidden;
}

.search-type-toggle-vertical .MuiToggleButton-root {
  width: 100%;
  padding: 10px;
  border-radius: 0 !important;
  border: none;
  border-bottom: 1px solid var(--border-color);
  justify-content: flex-start;
  text-transform: none;
}

.search-type-toggle-vertical .MuiToggleButton-root:last-child {
  border-bottom: none;
}

.date-range-filter-sidebar {
  width: 100%;
}

.date-range-filter-sidebar .react-datepicker-wrapper,
.date-range-filter-sidebar .react-datepicker__input-container {
  width: 100%;
}

.date-range-filter-sidebar input {
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid var(--border-color);
  background-color: var(--input-bg);
  color: var(--text-primary);
}

.sidebar-actions {
  margin-top: auto;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.archived-inventory-button {
  margin-top: 10px !important;
}

/* Updated controls container for simplified header */
.controls-container-simplified {
  flex: 0 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: var(--bg-primary);
  box-shadow: 0 4px 6px var(--shadow-color);
  border-bottom: 1px solid var(--border-color);
  z-index: 10;
  width: 100%;
}

.video-content-area {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  background-color: var(--bg-primary);
}

.search-input-rounded {
  border-radius: 20px !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
}

/* Media query for responsive design */
@media (max-width: 1024px) {
  .video-filters-sidebar {
    width: 200px;
  }

  .search-input-rounded {
    width: 250px;
  }
}

@media (max-width: 768px) {
  .app-container {
    flex-direction: column;
  }

  .video-filters-sidebar {
    width: 100%;
    height: auto;
    max-height: 250px;
    overflow-y: auto;
    border-right: none;
    border-bottom: 1px solid var(--border-color);
  }

  .video-content-area {
    height: calc(100% - 250px);
  }

  .controls-container-simplified {
    flex-direction: column;
    align-items: center;
    padding: 10px;
  }

  .search-input-rounded {
    width: 100%;
    max-width: 300px;
  }

  .search-bar-container-video {
    width: 100%;
  }

  .search-input-wrapper {
    width: 100%;
  }
}

/* Fix for controls header to prevent overlap */
.controls-header {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
}

.video-management-title {
  font-size: 24px;
  margin: 0 0 10px 0;
  color: var(--text-primary);
  font-weight: 600;
  text-align: left;
  width: 100%;
}

/* Add styles for the improved content type filters */
.content-type-filters {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 10px;
}

.content-filter-option {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 10px 12px;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s ease;
  border: 1px solid transparent;
}

.content-filter-option:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.content-filter-option.active {
  background-color: rgba(203, 45, 62, 0.1);
  border: 1px solid rgba(203, 45, 62, 0.2);
}

.content-filter-option svg {
  color: var(--text-primary);
  font-size: 20px;
}

.content-filter-option.active svg {
  color: #cb2d3e;
}

.content-filter-option span {
  font-size: 14px;
  font-weight: 500;
}

/* Fix the date range picker in sidebar */
.sidebar-date-picker {
  margin-top: 10px;
  width: 100%;
}

/* Improve Archive button styling */
.archived-toggle-button {
  margin-top: 10px !important;
  padding: 8px 10px !important;
  border-color: rgba(203, 45, 62, 0.6) !important;
  color: var(--text-primary) !important;
  transition: all 0.2s ease !important;
  text-transform: none !important;
  border-radius: 6px !important;
}

.archived-toggle-button:hover {
  background-color: rgba(203, 45, 62, 0.1) !important;
}

.archived-toggle-button.active {
  background-color: rgba(203, 45, 62, 0.15) !important;
  border-color: #cb2d3e !important;
}

.archived-toggle-button svg {
  color: #cb2d3e;
  margin-right: 8px !important;
}

/* Fix dark mode specific styles */
[data-theme='dark'] .content-filter-option:hover {
  background-color: rgba(255, 255, 255, 0.05);
}

[data-theme='dark'] .content-filter-option.active {
  background-color: rgba(203, 45, 62, 0.2);
  border: 1px solid rgba(203, 45, 62, 0.3);
}

[data-theme='dark'] .content-filter-option.active svg {
  color: #ff4d63;
}

/* Make video datagrid properly fill container */
.video-datagrid {
  height: 100%;
  width: 100%;
}

.video-datagrid .MuiDataGrid-main {
  height: 100%;
}

.video-datagrid .MuiDataGrid-virtualScroller {
  height: 100% !important;
}

/* Fix search section layout */
.search-section {
  display: flex;
  width: 100%;
}

/* Adjust search bar container in the simplified header */
.controls-container-simplified .search-bar-container-video {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  margin: 0;
}

/* Make search input wrapper more flexible */
.controls-container-simplified .search-input-wrapper {
  flex: 1;
  max-width: 400px;
}

/* Mobile adjustments for search header */
@media (max-width: 768px) {
  .controls-header {
    align-items: flex-start;
  }

  .controls-container-simplified .search-bar-container-video {
    flex-wrap: wrap;
    gap: 8px;
    justify-content: flex-start;
  }

  .controls-container-simplified .search-input-wrapper {
    max-width: 100%;
    width: 100%;
  }
}

/* Make the date picker more visible as an actual input field */
.date-range-filter-sidebar {
  width: 100%;
  font-family: 'Montserrat', sans-serif;
}

/* Ensure the wrapper and container take full width */
.react-datepicker-wrapper,
.react-datepicker__input-container {
  width: 100% !important;
  display: block !important;
}

/* Style the actual input element to be more prominent */
.react-datepicker__input-container input {
  width: 100% !important;
  padding: 10px 12px !important;
  border-radius: 6px !important;
  border: 1px solid var(--border-color) !important;
  background-color: var(--input-bg) !important;
  color: var(--text-primary) !important;
  font-size: 14px !important;
  height: auto !important;
  cursor: pointer !important;
}

/* Remove date-picker-wrapper styles that might be hiding the input */
.date-picker-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
}

/* Style the CustomInput component to show the actual input field */
.custom-date-input {
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
}

.custom-date-input input {
  width: 100%;
  padding-right: 30px; /* Make room for the clear button */
}

.custom-date-input .clear-date-button {
  position: absolute;
  right: 8px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: var(--text-secondary);
  padding: 2px;
  font-size: 14px;
}

.custom-date-input .clear-date-button:hover {
  color: #cb2d3e;
}

/* Update the user filter styling to match date picker */
.sidebar-section .MuiOutlinedInput-root.owner-filter {
  border-radius: 6px !important;
  font-family: 'Montserrat', sans-serif !important;
  font-size: 14px !important;
  background-color: var(--input-bg) !important;
}

.sidebar-section .owner-filter .MuiSelect-select {
  padding: 10px 12px !important;
  background-color: var(--input-bg) !important;
  color: var(--text-primary) !important;
  height: auto !important;
}

.sidebar-section .owner-filter .MuiOutlinedInput-notchedOutline {
  border-color: var(--border-color) !important;
  border-width: 1px !important;
}

.sidebar-section .owner-filter:hover .MuiOutlinedInput-notchedOutline {
  border-color: #cb2d3e !important;
}

.sidebar-section .owner-filter.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #cb2d3e !important;
  border-width: 1px !important;
}

/* Style the dropdown icon to match theme */
.sidebar-section .owner-filter .MuiSelect-icon {
  color: var(--text-secondary) !important;
}

/* Update dropdown menu styling */
.MuiMenu-paper {
  border-radius: 6px !important;
}

/* Add after video-management-title styles */

.breadcrumb-container {
  display: flex;
  align-items: center;
  gap: 8px;
  font-family: 'Montserrat', sans-serif;
  color: var(--text-primary);
}

.breadcrumb-separator {
  color: var(--text-secondary);
  font-size: 16px;
  margin: 0 4px;
}

.breadcrumb-root {
  color: var(--text-secondary);
  text-decoration: none;
  cursor: pointer;
  transition: color 0.2s ease;
}

.breadcrumb-root:hover {
  color: #cb2d3e;
}

.breadcrumb-current {
  color: var(--text-primary);
  font-weight: 600;
}
